.capsule {
  color: black;
  padding: 10px;
  display: inline-block;
  border: 2px solid black;
  border-radius: 6px;
  margin: 10px;

  .capsule_text {
    font-size: 1.2rem;
  }
}

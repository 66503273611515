.requests-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  background-color: #f9fafb;
}

.requests-history-accounting {
  min-width: 100%;
  // height: 124px;
  display: flex;
  align-items: center;
  padding: 24px;
  justify-content: center;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  margin: auto;
  overflow: hidden;
  margin-bottom: 24px;
  background-color: white;

  .requests-accounting-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .requests-accounting-col {
    height: 84px;
  }

  .requests-history-accounting-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .requests-history-accounting-col {
    // height: 84px;
    .question-card-description {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      height: inherit;
      overflow: hidden;
      color: #4b5563;
    }
  }

  .requests-history-accounting-date-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .p-title {
    font-weight: 500;
    font-size: clamp(.6rem, 1.84vw, .8rem);
    line-height: clamp(1rem, 1.84vw, 1.75rem);
    color: #4b5563;
  }

  .title {
    font-weight: 600;
    font-size: clamp(.8rem, 1.84vw, 1.25rem);
    line-height: clamp(1rem, 1.84vw, 1.75rem);
    color: #1c64f2;
  }

  .title-date {
    color: #111928;
  }

  .title-i {
    font-weight: 500;
    color: #111928;
  }

  .status {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .status-popover-option-icon {
      cursor: pointer;
    }

    .title-date-p {
      font-size: 12px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #4b5563;
      margin-right: 35px;
    }
  }

  .progress-history-card {
    width: 300px;
    padding: 0;
    margin: 0px;
    height: 27px;
    background-color: transparent;

    .ant-progress-bg {
      background-color: #f05252;
      border-radius: 2px !important;
      height: 20px !important;
    }

    .ant-progress .ant-progress-success-bg {
      border-radius: 2px !important;
      height: 20px !important;
    }

    .ant-progress .ant-progress-inner {
      border-radius: 2px !important;
      // width: 265px;
      height: 20px;
      // background-color: #F05252;
    }
  }

  .progress-request-card {
    width: 100%;
    padding: 0;
    margin: 0;
    height: 27px;
    background-color: transparent;

    .ant-progress-bg {
      border-radius: 2px !important;
      height: 20px !important;
    }

    .ant-progress .ant-progress-success-bg {
      border-radius: 2px !important;
      height: 20px !important;
    }

    .ant-progress .ant-progress-inner {
      border-radius: 2px !important;
      // width: 265px;
      height: 20px;
    }
  }
}
.custom-request-card:hover{
  border: .25rem solid #1c64f2;
  border-radius: 1rem;
  cursor: pointer;
}
.custom-request-card > * {
  border: none !important;

}
.request-details-body {
  width: 100%;
  display: flex;
  flex-direction: column;

  .request {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #f9fafb;
    margin: auto;

    .request-wrapper {
      // width: 864px;
      width: 74%;
      display: flex;
      overflow: hidden;
      flex-direction: column;

      .request-tabs-navigator-tabs-div {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        // background-color: #f05252;
        gap: 1px;
        width: 100%;
      
        font-size: clamp(.3rem, 2.25vw, 1rem);
      }
    }

    .request-container {
      height: 100%;
    }

    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #111928;
    }

    h6 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #111928;
    }

    p {
      font-weight: 500;
      font-size: clamp(.8rem, 1.84vw, 1rem);
      line-height: 24px;
      color: #4b5563;
    }

    .request-details {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid #d1d5db;
      border-radius: 8px;
      padding: 20px 24px 20px 24px;
    }

    .request-details-wrapper {
      margin-top: 30px;
    }

    .request-steps-container {
      width: 100%;
    }

    .request-submitted {
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid #d1d5db;
      border-radius: 8px;
      padding: 20px 24px 20px 16px;
      overflow-y: scroll;

      .request-submitted-steps-header {
        line-height: 24px;
        font-weight: bold;
      }

      .request-submitted-steps-expert-card-wrapper {
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        margin-block: 10px;
        cursor: pointer;

        .request-submitted-steps-expert-card-container {
          width: 100%;
          height: 100%;
          padding: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border: 1px solid #1c64f2;
          border-radius: 4px;
        }

        // Small profile card
        .assigned-to-profile-name {
          font-size: 14px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          img {
            border-radius: 50%;
          }
        }
      }

      .assigned-to-profile-role {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #6b7280;
      }

      // Steps tail
      .ant-steps.ant-steps-vertical
        > .ant-steps-item
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        width: 1px;
        height: 160%;
      }

      :where(.css-dev-only-do-not-override-lzsong).ant-steps.ant-steps-vertical.ant-steps-dot
        .ant-steps-item
        > .ant-steps-item-container
        > .ant-steps-item-tail {
        top: 0px;
        inset-inline-start: 0;
        margin: 0;
        padding: 16px 0 8px;
      }

      :where(.css-dev-only-do-not-override-1vvk4g5).ant-steps.ant-steps-vertical.ant-steps-dot
        .ant-steps-item
        > .ant-steps-item-container
        > .ant-steps-item-tail {
        top: 0px;
      }

      :where(.css-dev-only-do-not-override-lzsong).ant-steps
        .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
        background-color: #bfbfbf;
      }
    }

    .request-submitted::-webkit-scrollbar {
      display: none;
    }

    .general p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #4b5563;
    }

    .general {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      border: 1px solid #d1d5db;
      border-radius: 8px;
      padding: 13px 20px 13px 20px;
      background-color: #f9fafb;
    }

    .request-group {
      display: grid;
      gap: 15px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 24px;
    }

    .round {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #1c64f2;
      border-radius: 50%;
    }

    .line {
      display: block;
      width: 2px;
      height: 50px;
      background-color: #bfbfbf;
      transform: translate(9px, -3px);
    }

    .submitted-container {
      display: flex;
      gap: 20px;
    }
  }
}

.request-tabs-navigator-container {
  margin-bottom: 24px;
  height: 53px;
  display: flex;
  width: 100%;
  .request-tabs-navigator-tabs {
    height: 53px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6b7280;
  }
  @media  screen and (max-width: $small) {
    .request-tabs-navigator-tabs {
      width: auto;
    }
  }
}

.status-popover-content-container {
  display: flex;
  justify-content: center;
  height: fit-content;
  width: 195px;
  flex-direction: column;

  .status-popover-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
  }

  .status-popover-cancel-button {
    font-size: 16px;
    font-weight: 400;
    color: #f05252;
    padding: 10px 5px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: #dfdfdf;
    }
  }
}

.request-details-for-question-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 20px 24px 20px 24px;

  ::-webkit-scrollbar {
    display: none;
  }

  .request-container {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #111928;
  }

  h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #111928;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4b5563;
  }

  .general p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4b5563;
  }

  .general {
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    max-height: 120px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 13px 20px 13px 20px;
    background-color: #f9fafb;
  }
}

.request-details-for-question-modal::-webkit-scrollbar {
  display: none;
}
.ant-popover-content{
  min-width: 10rem !important;
  max-width: 30rem !important;
}


.cursorBlink {
  font-size: 30px;          /* Adjust size as needed */
  animation: blink 0.8s steps(2, start) infinite;  /* Blinking effect */
  cursor: pointer;          /* Set cursor to pointer for hand */
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

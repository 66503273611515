.admin-dashboard {
  .admin-container {
    .title {
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;
      color: #111928;
    }
    .page-card-header {
      .ant-card-body {
        padding: 15px;
        text-align: center;
      }
      .card-title {
        font-weight: 500;
        font-size: 16px;
      }
      .card-value {
        font-weight: 700;
        font-size: 18px;
      }
    }
    .table-action-column-wrapper {
      margin-inline: 50%;
    }
  }
}
.action-popover {
  font-size: 16px;
  font-weight: 400;
  color: #f05252;
  padding: 10px 5px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: #dfdfdf;
  }
}

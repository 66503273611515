.message-body {
  background-color: #f9fafb;
  margin: auto;
  width: 100%;
  height: 100%;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 10px 10px;
}

.request-message-details-with-border {
  width: 100%;
  height: 100%;
  background-color: white;
}

.request-message-wrapper {
  width: 100%;
  max-height: 700px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.request-message-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border-radius: 8px;

  .send p {
    margin: auto;
    color: white;
  }

  .write {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border: none;
    outline: none;
    background-color: #f9fafb;
  }
}

.request-message-submitted {
  width: 264px;
  height: 608px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 20px 24px 20px 16px;
}

.general p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4b5563;
}

.general {
  max-width: 816px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 13px 20px 13px 20px;
  background-color: #f9fafb;
  overflow-y: scroll;
  word-wrap: break-word;
}

.general::-webkit-scrollbar {
  visibility: hidden;
}

.request-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1em;
}

.submitted-container {
  display: flex;
  gap: 20px;
}

.message-box p {
  width: fit-content;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #111328;
  padding: 16px;
  background: #eef1f4;
  border-radius: 16px 16px 16px 0;
  margin: auto;
}

.message-time p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
}

.name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1c64f2;
  margin: auto;
}

.message-container {
  display: flex;
  align-items: flex-end;
  gap: 24px;
}

.message-area {
  overflow-y: auto;
  // -ms-overflow-style: none;
  // scrollbar-width: 10px;
 
  padding-bottom: 20px;
  padding-right: 20px;
  // position: relative;
}

.zoom-meeting-prechatview {
  position: absolute;
  bottom: 9%;
  left: 0;
  width: 100%;
  height: 18rem;
  background: #f9fafb;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 10px 10px;
  border-bottom: none;
  z-index: 2;
}

.zoom-meeting-chatview {
  width: 100%;
  height: 13rem;
  background: #f9fafb;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 10px 10px;
  border-bottom: none;
}
.message-area > :first-child {
  margin-top: auto !important;
}



.message-container-Right .message-box p {
  width: fit-content;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #111328;
  padding: 16px;
  background: #eef1f4;
  border-radius: 16px 16px 0 16px;
  margin: auto;
}

.message-container-Right .message-time {
  margin-block: 5px;
  text-align: right;
}

.message-container-date-box {
  width: fit-content;
  background: #8fa0af;
  border-radius: 12.5px;
  margin: auto;
  margin-block: 26px;
  /* padding: 2px 8px; */
}

.message-container-date-box-p {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px;
  padding-inline: 8px;
  padding-block: 2px;
  color: white !important;
  font-style: normal;
  text-align: center;
}

.text-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #f8f9fa;
  padding: 8px 12px;
}

.icon-write {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 88%;

  .ant-upload-list.ant-upload-list-picture {
    position: absolute;
    width: 30%;
    margin: 20px;
    bottom: 70%;
  }
}

.send {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1c64f2;
  border-radius: 8px;
  color: white;
  text-align: center;
  // padding: 8px 12px;
  cursor: pointer;
}

.videosend {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
}

#zmmtg-root {
  display: none;
  min-width: 0 !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.message-group-view-body {
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;

  .message-group-left-container {
    width: 25%;
    box-shadow:
      0px 0px 4px rgba(2, 17, 37, 0.04),
      2px 0px 8px rgba(2, 17, 37, 0.04),
      6px 0px 16px rgba(2, 17, 37, 0.04);
    border-right: 1px solid #cdd5de;
    background-color: #ffffff;
    
    .message-group-search-container {
      padding: 24px;

      .message-group-search {
        border: 1px solid rgba(209, 213, 219, 1);
      }
    }

    .message-group-left-container-card-group {
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .message-group-left-container-card-group::-webkit-scrollbar {
      display: none;
    }
  }
  .message-group-right-container {
    width: 73%;
    padding-left: 0px;
  }
  @media  screen and (max-width: $large) {
    .message-group-left-container{
      width: 100%;
    }
    .message-group-right-container {
      width: 100%;
      // padding-left: 20px;
    }
  }
 
}

.messaging-group-card-view-wrapper {
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  .messaging-group-card-view-container {
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 3%;
    border-radius: 4px;
    cursor: pointer;

    .messaging-group-card-profile-icon {
      height: 100%;

      .messaging-group-card-profile-icon-img {
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }
    }

    .messaging-group-card-user-detail {
      width: 70%;
      overflow: hidden;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
      text-decoration-color: #4b5563;
      white-space: nowrap;

      .messaging-group-card-profile-name {
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
      }

      .messaging-group-card-last-chat {
        display: inline;
        color: #4b5563;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .messaging-group-card-user-timestamp-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }

    .messaging-group-card-user-time-stamp-detail {
      display: flex;
      flex-direction: column;
      align-items: end;
      width: 100%;

      .messaging-group-card-time-stamp {
        color: #8fa0af;
        white-space: nowrap;
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
      }

      .messaging-group-card-unread-message-indicator {
        display: flex;
        height: 18px;
        width: 18px;
        background: #1c64f2;
        border-radius: 10px;
        color: #ffffff;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }

      .messaging-group-card-user-message-indicator {
        display: flex;
        height: 6px;
        width: 6px;
        margin-top: 6px;
        background: #1c64f2;
        border-radius: 10px;
        color: #ffffff;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
    }
  }

  .messaging-group-card-view-container:hover {
    background-color: #f0f0f1;
  }
}

.payment-detail {
  width: 100%;

  p {
    margin-bottom: 0px;
  }

  .ant-card-body {
    padding: 20px;
  }

  .small-text {
    font-size: 14px !important;
    color: #4b5563 !important;
    font-weight: 500 !important;
    margin-bottom: 5px;
  }

  .big-text {
    font-size: 16px !important;
    color: #111928 !important;
    font-weight: 600 !important;
  }
}

.meeting-time {
  border: 1px solid gray;
  border-radius: 5px;
  width: 125px;
  height: 90px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (min-width: $large) {
  .meeting-time::after {
    content: '';
    height: 25px;
    width: 25px;
    border-radius: 50%;
    color: gray;
    display: block;
    border: 2px solid gray;
    position: absolute;
    right: 22px;
    top: 10px;
  }
}
.selected-meeting-time {
  border: 1px solid #1c64f2;
  color: #1c64f2;
  font-weight: bold;
}

.selected-meeting-time::after {
  color: #1c64f2;
  border: 7px solid #1c64f2;
}

.edit-availability {
  padding-top: 2rem;
}

.edit-availability span {
  font-size: 1rem;
  color: #1c64f2;
  font-weight: bold;
  cursor: pointer;
}

.sub-title {
  max-width: 45rem;
}

.switch-and-day {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switch-and-day p {
  margin-bottom: 0;
}

.day-main-container {
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.from-and-to-time {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.day-main-container-row {
  margin-bottom: 1.2rem;
  justify-content: space-between;
}

//edited by prince start for Pick meeting date and time
.selected-times ul li {
  text-decoration: none;
  list-style: none;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
  margin: 0 7px 7px 0;
  cursor: pointer;
}

.selected-time-active,
.selected-times ul li:hover {
  border: 1px solid #478bff;
  background-color: #478bff;
  color: #ffffff;
  font-weight: bold;
}

.selected-times {
  max-height: 35vh;
  overflow: auto;
}

.selected-times ul {
  height: 100%;
  overflow: auto;
}

.time-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgb(243 243 243 / 62%);
  border-radius: 10px;
  flex-direction: column;
}
.ant-picker-calendar-header {
  display: none !important;
}
.calendar-section h3 {
  padding: 10px;
}
.calendar-section-col {
  border: 0.1rem solid #cccccc;
  border-radius: 5px;
}

.schedule-meeting-row {
  justify-content: space-around;
  margin-top: 50px;
}

.duration-col p {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.shedule-time-timezone-section {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.ant-loader-icon {
  font-size: 1.25rem;
  color: #1890ff;
}

.timezone-select-bar {
  width: 100%;
}

.time-section p {
  font-size: 1.1rem;
  font-weight: 200;
  margin-bottom: 5px;
}

.time-section .clock-icon {
  font-size: 10rem;
  opacity: 0.7;
}

.selected-times ul {
  width: 100%;
  padding: 0;
}

.MuiPopper-root {
  z-index: 5 !important;
}
.react-draggable{
  position: absolute !important;
  left: 20vw;
  top: 0;
}
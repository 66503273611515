// to get this color use like  color: map-get($colors, "success");
$colors: (
  light: #fff,
  dark: #0f0f0f,
  primary: #1877f2,
  secondary: #ffba00,
  dark-grey: #939393,
  warning: #fd3c3c,
  success: #099670,
  facebook: #4267b2,
  grey: #d9d9d9,
  ratting: #fadb14,
  instagram: #e1306c,
);
$maxLoop: 100;
$step: 5;
$red: red;
$grid-breakpoints: (
  xs: 400px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$border-color: #e5e7eb;
$shadow:
  0px 4px 6px -1px rgb(0 0 0 / 10%),
  0px 2px 4px -2px rgb(0 0 0 / 5%);
/* Related to media query */
@mixin ele-font($family) {
  font-family: $family;
}

@mixin media-large-tablet {
  @media (min-width: 992px) and (max-width: 1199.98px) {
    @content;
  }
}

@mixin media-small-tablet {
  @media (min-width: 768px) and (max-width: 991.98px) {
    @content;
  }
}

@mixin media-landscape-mobile {
  @media (min-width: 576px) and (max-width: 767.98px) {
    @content;
  }
}

@mixin media-lg {
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin media-md {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin media-sm {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin media-mobile {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin media-mobile-medium {
  @media (max-width: 360px) {
    @content;
  }
}

@mixin media-mobile-small {
  @media (max-width: 320px) {
    @content;
  }
}

// new @media
@mixin min-1600 {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin min-1400 {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin min-1200 {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin min-992 {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin min-768 {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin min-575 {
  @media (min-width: 575px) {
    @content;
  }
}

@for $i from 0 through 60 {
  .lh-#{$i} {
    line-height: $i * 1px !important;
  }
}

.b0 {
  border: none !important;
}
.mt0 {
  margin-top: 0 !important;
}

.link {
  color: #1c64f2;
  font-weight: bold;
}

$sizes: 120;
@mixin margin-classes {
  @for $i from 0 through $sizes {
    $margin: $i * 0.25rem;

    /* margin #{$margin} */
    .m-#{$i} {
      margin: $margin;
    }

    .ml-#{$i} {
      margin-left: $margin;
    }

    .mr-#{$i} {
      margin-right: $margin;
    }

    .mt-#{$i} {
      margin-top: $margin;
    }

    .mb-#{$i} {
      margin-bottom: $margin;
    }

    .mx-#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }

    .my-#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}

@include margin-classes;

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;

    /* padding #{$padding} */
    .p-#{$i} {
      padding: $padding;
    }

    .pl-#{$i} {
      padding-left: $padding;
    }

    .pr-#{$i} {
      padding-right: $padding;
    }

    .pt-#{$i} {
      padding-top: $padding;
    }

    .pb-#{$i} {
      padding-bottom: $padding;
    }

    .px-#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }

    .py-#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}

@include padding-classes;

@for $i from 0 through ceil($maxLoop/$step) {
  $value: ($i - 0) * $step + 0;

  .pa#{$value} {
    padding: $value + px;
  }

  .pl#{$value} {
    padding-left: $value + px;
  }

  .pt#{$value} {
    padding-top: $value + px;
  }

  .pb#{$value} {
    padding-bottom: $value + px;
  }

  .pr#{$value} {
    padding-right: $value + px;
  }

  .px#{$value} {
    padding-left: $value + px !important;
    padding-right: $value + px !important;
  }

  .py#{$value} {
    padding-top: $value + px;
    padding-bottom: $value + px;
  }

  .ma#{$value} {
    margin: $value + px;
  }

  .mt#{$value} {
    margin-top: $value + px;
  }

  .mb#{$value} {
    margin-bottom: $value + px !important;
  }

  .ml#{$value} {
    margin-left: $value + px;
  }

  .mr#{$value} {
    margin-right: $value + px !important;
  }

  .mx#{$value} {
    margin-left: $value + px;
    margin-right: $value + px;
  }

  .my#{$value} {
    margin-top: $value + px;
    margin-bottom: $value + px;
  }

  .br#{$value} {
    border-radius: $value + px;
  }
}

.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.tc {
  text-align: center !important;
}

.tr {
  text-align: right !important;
}

.tl {
  text-align: left !important;
}

.d-flex {
  display: flex;
}
.d-flex-between {
  display: flex;
  justify-content: space-between;
}
.d-flex-center {
  display: flex;
  align-items: center;
}
.d-flex-a-c {
  display: flex;
  align-items: center;
  justify-content: center;
}
.f-flex-a-end {
  display: flex;
  align-items: flex-end;
}
.f-flex-a-e {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.f-flex-a-s-j-e {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.i-flex {
  display: inline-flex;
}
.option-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
}
.cursor-p {
  cursor: pointer;
}
.b500 {
  font-weight: 500;
}
.bold {
  font-weight: bold;
}
.b700 {
  font-weight: 700;
}

.flex-x {
  display: flex;
  flex-direction: row;

  &.align-center {
    align-items: center;
  }

  &.align-stretch {
    align-items: stretch;
  }

  &.center {
    align-items: center;
    justify-content: center;
  }
}

.flex-y {
  display: flex;
  flex-direction: column;

  &.center {
    align-items: center;
    justify-content: center;
  }
}

.space-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.full-height {
  height: 100vh;
}

.fill-width {
  width: 100% !important;
}

.fill-height {
  height: 100% !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hide {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.w100 {
  width: 100%;
}

.float-r {
  float: right;
}
.back-icon {
  vertical-align: middle;
  margin-right: 8px;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    color: #1c64f2;
    font-size: 22px;
  }
}

.lineThrough {
  text-decoration: line-through;
}

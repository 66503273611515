.dashboard {
  padding: 40px 80px;
  min-height: calc(100vh - 135px);
  min-width: auto;
  @media screen and (max-width: 1200px) {
    padding: 40px 20px;
  }
  @media screen and (max-width: 576px) {
    padding: 40px 10px;
  }
  .profile {
    .card-wrapper {
      // width: 800px;
      width: 100%;
    }

    .ant-card {
      box-shadow: $shadow;
    }

    .ant-card-body {
      padding: 24px 40px;
    }
  }
}

.dashboard-footer {
  &.ant-layout-footer {
    background: white;
  }

  bottom: 0;
  width: 100%;
}

.dashboard-questions_Section {
  background-color: white;
  position: absolute;
  inset: 0px 0px 0px 0px;
  padding: 20px;
  width: 95%;
  min-height: 89%;
  max-height: 95%;
  border-radius: 6px;
  border-color: #1c64f2;
  box-shadow: 0 0 0 2px rgba(5, 130, 255, 0.08);

  .Typewriter__wrapper {
    font-weight: 700;
    font-size: 18px;
    line-height: 10px;
    letter-spacing: -0.01em;
    color: black;
  }

  .Typewriter__cursor {
    color: black;
    font-size: 18px;
    font-weight: 700;
  }

  .category_container {
    margin-top: 30px;
    top: 100px;
    height: 80%;
    overflow: auto;
  }

  .footer_buttons {
    display: flex;
    justify-content: space-between;
  }
}

#geminiTypewriter {
  border: 0.1px solid #d9d9d9;
  padding: 10px;
  border-radius: 5px;
  // min-height: 10rem;
  position: relative;

  .Typewriter__wrapper {
    line-height: 2rem;
  }

  #editIcon {
    font-size: 2rem;
    color: #1c64f2;
    // bottom: 5px;
    position: absolute;
    right: 5px;
  }
}

.geminiTypewriterClass {
  cursor: pointer;
}

.geminiTypewriterClass:hover {
  border: 1px solid #1c64f2 !important;
}

#loadingSpan {
  font-size: 1.3rem;
  color: #1c64f2;
  letter-spacing: 2px;
}

.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 1rem;
  padding-block: 2rem;

  table {
    border-collapse: collapse;
    overflow: scroll;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
    margin-bottom: 1rem;
  }

  th,
  td {
    padding: 0.65rem;
    background-color: rgba(255, 255, 255, 0.2);
    color: black;
  }

  thead {
    th {
      background-color: #f5f5f5;
      text-wrap: nowrap;
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: rgba(200, 200, 200, 0.3);
      }
    }
    td {
      position: relative;
      &:hover {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: -9999px;
          bottom: -9999px;
          background-color: rgba(200, 200, 200, 0.3);
          z-index: -1;
        }
      }
      p,
      .ant-form-item {
        margin: auto;
      }
    }
  }
  .width-12 {
    width: 12%;
  }
  .remove-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
@media screen and (max-width: 800px) {
  .table-container {
    width: 700px;
    overflow: scroll;
  }
}
@media screen and (max-width: 700px) {
  .table-container {
    width: 650px;
    overflow: scroll;
  }
}
@media screen and (max-width: 600px) {
  .table-container {
    width: 450px;
    overflow: scroll;
  }
}
@media screen and (max-width: 500px) {
  .table-container {
    width: 400px;
    overflow: scroll;
  }
}
@media screen and (max-width: 450px) {
  .table-container {
    width: 330px;
    overflow: scroll;
  }
}
.question-create {
  .ant-progress {
    margin-inline-end: 0px;
  }

  .main-logo {
    font-weight: 700;
    font-size: 18px;
    color: #111928;
  }

  .ant-card-body {
    width: 950px;
    @media  screen and (max-width: 992px) {
      width: 100%;
    }
    .m-title {
      font-size: 18px;
    }

    .desc-text {
      margin-left: 25px;
      margin-right: 25px;

      .ant-form-item-label {
        label {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          display: flex;
          align-items: center;
          color: #6b7280;
        }
      }
    }
  }

  .certificate-section {
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 25px;
    border: 1px solid #eee;
    .ant-input-number-lg {
      width: 100%;
    }
  }

  .remove-section-icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    margin-block-end: 30px;
    margin-inline-end: 20px;
    width: 100%;
    // background-color: #111928;
    color: #000;

    & :hover {
      color: #6b7280;
    }
  }
  :where(.css-dev-only-do-not-override-1vvk4g5).ant-select .ant-select-arrow {
    pointer-events: auto;
  }
}

.FindExpert-body {
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .section-title {
    font-size: clamp(1.5rem , 2.5vw, 2rem);
    font-weight: 800;
    line-height: 40px;
  }

  .profile {
    width: inherit;
    display: flex;
    padding: 40px 80px;
    flex-direction: row;
    gap: 40px;

    .profile-details {
      width: inherit;

      .profile-main-category {
        font-size: 18px;
        padding-top: 6px;
        font-weight: 500;
        line-height: 22.5px;
        color: #6b7280;
      }
    }

    .profile-img {
      width: 180px;
    }

    .profile-img img {
      width: 180px;
      height: 180px;
      border-radius: 100px;
      margin-bottom: 24px;
      object-fit: cover;
    }

    .profile-address div {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 12px;
    }

    .profile-address {
      margin-top: 40px;
    }

    .profile-address img {
      width: 22px;
      height: 17.05px;
      color: #1c64f2;
    }

    .profile-address p {
      font-weight: 400px;
      font-size: 16px;
      line-height: 24px;
      color: #6b7280;
      margin: 0;
    }
  }
 
  .biography,
  .expert-review {
    width: inherit;
    padding: 40px 80px;
    background-color: white;

    .biography-p {
      flex: none;
      order: 1;
      flex-grow: 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #6b7280;
    }
  }

  .jobs {
    width: inherit;
    padding: 40px 80px;
  }

  .jobs-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2em;
    column-gap: 20px;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    margin-top: 40px;
  }
  @media screen and (max-width: $large) {
    .jobs-details {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: $medium) {
    .jobs-details {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .jobs-details-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    height: 200px;
    border: 1px solid #e5e7eb;
    padding: 32px 16px;
    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.1),
      0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  @media screen and (max-width: $medium) {
    .profile,
    .biography,
    .expert-review,
    .jobs {
      padding: 10px 10px;
    }
    
  }
  .jobs-details-card-img {
    width: 40px;
    // height: 35.2px;
  }

  .jobs-details-card-heading {
    font-weight: 800;
    font-size: 22px;
    line-height: 48px;
    color: #111928;
  }

  .jobs-details-card-paragraph {
    font-weight: 400;
    font-size: 16px;
    color: #6b7280;
  }

  .btn-view {
    width: 140px;
    height: 52px;
    margin: auto;
    margin-top: 50px;
  }

  .customer {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  .customer-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #111928;
  }

  .customer-name span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17.5px;
    color: #6b7280;
  }

  .customer-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6b7280;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #e5e7eb;
    margin-bottom: 32px;
  }
}

/* #profile{
      background-color:red;
      width:1440px;
      margin:auto;
      height:391px;
      padding:0;
  } */
.profile-name {
  width: inherit;
  display: flex;
  justify-content: space-between;
}

.profile-expert-rating-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 26px;
  padding: 4px 6px;
  gap: 4px;
  border-radius: 4px;
  color: white;
  background-color: #1c64f2;
}

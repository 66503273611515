.request-assigned-to-container {
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 20px 24px 20px 24px;
  margin-bottom: 24px;

  .assigned-to-profile-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .assigned-to-profile-proposal {
    font-size: 1rem;
    line-height: 1.5em;
    height: auto;
    font-family: inherit;
    white-space: pre-wrap;
    max-width: 100%;
    box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.096);
    overflow: hidden;
    &--hide {
      height: 6em;
    }
  }

  .assigned-to-profile-left-container {
    display: flex;
    cursor: pointer;
  }

  .assigned-to-profile-icon {
    width: 60px;
    height: 60px;
    border-radius: 30px;

    img {
      border-radius: 50%;
    }
  }

  .assigned-to-profile-detail {
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .assigned-to-profile-name {
      font-weight: 700;
      font-size: 20px;
    }

    .assigned-to-profile-role {
      //styleName: text-base/font-medium;
      font-family: Inter;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #6b7280;
    }
  }

  .assigned-to-rating-container {
    display: flex;
    flex-direction: column;

    .assigned-to-rating-container-div {
      //styleName: text-base/font-medium;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #6b7280;
    }
  }

  .assigned-to-profile-button-group {
    height: 60px;
    display: flex;
    gap: 16px;
    justify-content: end;
    
    @media  screen and (max-width: 768px) {
      justify-content: start;
      margin-top: 2rem;
    }

    .assigned-to-profile-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
     
      // width: 134px;
      border-radius: 8px;
    }
  }
}

.request-span {
  display: flex;
  flex-direction: column;
  line-height: normal;

  ul {
    display: flex;
    flex-direction: column;
    li:first-child {
      margin-top: 5px;
    }
  }
}

.radio-button-image-list {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1rem 0.5rem 1rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  margin-bottom: 24px;
  ul {
    list-style-type: none;
  }
  li {
    display: inline-block;
  }

  input[type='radio'] {
    display: none;
  }

  label {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 10px;
    cursor: pointer;
  }

  label:before {
    background-color: white;
    color: white;
    content: ' ';
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  label img {
    height: 100px;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }

  :checked + label {
    border-color: #ddd;
  }

  :checked + label:before {
    content: '✓';
    background-color: grey;
    transform: scale(1);
  }

  :checked + label img {
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
  }
  span {
    display: block;
    text-align: center;
    font-weight: 500;
    text-transform: capitalize;
  }
}

.expert-detail-body {
  .expert-detail-left-container {
    width: 100%;

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
      border: 0px;
      background-color: #f9fafb;
    }

    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-bottom > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before,
    .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: 0px;
    }

    .ant-tabs-content-holder {
      border-left: 0px solid #acacac;
      border-right: 0px solid #acacac;
      padding: 0px;
    }

    :where(.css-dev-only-do-not-override-lzsong).ant-tabs
      .ant-tabs-tab.ant-tabs-tab-active
      .ant-tabs-tab-btn {
      color: #1c64f2;
      text-shadow: 0 0 0.25px currentcolor;
      text-decoration: underline;
      font-weight: 600;
    }

    :where(.css-dev-only-do-not-override-lzsong).ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    :where(.css-dev-only-do-not-override-lzsong).ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      position: relative;
      display: flex;
      transition: opacity 0.3s;
      justify-content: space-evenly;
    }

    :where(.css-dev-only-do-not-override-lzsong).ant-tabs {
      gap: 90px;
    }

    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
      margin-bottom: 0;
      height: 150px;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
    .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
    .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
      margin: 0;
    }

    .expert-detail-menu-container {
      padding: 25px;

      .expert-detail-menu-item {
        margin-block: 40px;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        &__isClicked {
          color: chartreuse;
          font-weight: 600;
        }

        &__isNotClicked {
          color: chocolate;
          font-weight: 800;
        }
      }
    }
  }

  .expert-detail-right-container {
    width: 100%;
    padding: 30px;
    border: 1px solid #f6f6f6;
    box-shadow:
      0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    .expert-detail-right-container-title {
      //styleName: text-xl/font-bold;
      font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
      color: #111928;
    }

    .expert-detail-right-wrapper {
      width: 95%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 25px;
      gap: 30px;

      .expert-detail-container-title {
        font-size: 20px;
        font-weight: 600;
      }

      .expert-detail-right-container-row-container {
        display: flex;
        justify-content: space-between;

        .expert-detail-right-container-question {
          //styleName: text-base/font-medium;
          width: 45%;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }

        .expert-detail-right-container-answer {
          width: 45%;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          overflow-wrap: break-word;
          
          .expert-bio {
            white-space: pre-line;
          }
        }
        @media screen and (max-width: 1200px) {
          .expert-detail-right-container-answer{
            text-align: right;
          }
        }
      }
    }
  }

  .expert-detail-update-button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}

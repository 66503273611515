.expert-container {
  .expert-requests-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 24px;
  }

  .expert-question-stats-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .expert-question-stats-card {
    display: flex;
    align-items: center;
    width: 95%;
    height: 95%;
    padding: 20px 0px;
    border: 1px solid rgba(0, 0, 0, 10%);
    border-radius: 8px;
    background-color: white;

    .expert-question-stats-card-content-wrapper {
      height: 86px;
      margin-inline: 20px;

      .expert-question-stats-card-content-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #4b5563;
        cursor: pointer;
      }
      .expert-question-stats-card-content-title:hover{
        color: #1677FF;
      }

      .expert-question-stats-card-content-title-container {
        align-items: end;

        .expert-question-stats-card-content-sub-value {
          margin-bottom: 5px;
          gap: 5px;

          .expert-question-stats-card-content-sub-value-text {
            font-size: 14px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            height: 100%;
          }

          .expert-question-stats-card-content-sub-value-icon {
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 3px;
          }
        }
      }

      .expert-question-stats-card-content-value {
        font-size: clamp(1.5rem, 2.5vw, 2.25rem);
        font-weight: 600;
        line-height: 57px;
        letter-spacing: 0em;
        text-align: left;
        color: #111928;
        cursor: pointer;
      }

      .expert-question-stats-card-content-value:hover{
        color: #1677FF;
      }
    }
  }
  .ant-pagination {
    display: flex;
    justify-content: flex-end;
  }
  .ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
  :where(.css-dev-only-do-not-override-lzsong).ant-pagination
    .ant-pagination-disabled:hover
    .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    border: 1px solid #d1d5db;
  }
  .ant-pagination-item {
    border: 1px solid #d1d5db;
  }

  :where(.css-dev-only-do-not-override-lzsong).ant-pagination .ant-pagination-item-active {
    background-color: #e1effe;
  }

  :where(.css-dev-only-do-not-override-lzsong).ant-pagination
    .ant-pagination-prev
    .ant-pagination-item-link {
    border: 1px solid #d1d5db;
  }

  :where(.css-dev-only-do-not-override-lzsong).ant-pagination
    .ant-pagination-next
    .ant-pagination-item-link {
    border: 1px solid #d1d5db;
  }
}

.expert-request-button-group {
  height: 100%;
  gap: 16px;

  .expert-request-button {
    height: 48px;
    width: 140px;
    border-radius: 8px;
  }
}

.expert-empty-new-request-container {
  display: flex;
  width: inherit;
  height: inherit;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #4b5563;
}

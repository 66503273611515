.review-modal-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;

  .modal-title-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .modal-title-text {
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
    }
  }

  .modal-body-wrapper {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }

  .modal-profile-wrapper {
    display: flex;
    height: max-content;
    align-items: center;
    justify-content: center;

    .assigned-to-profile-icon {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      img {
        border-radius: 50%;
      }
    }

    .assigned-to-profile-detail {
      padding: 0px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .assigned-to-profile-name {
        font-weight: 700;
        font-size: 20px;
      }

      .assigned-to-profile-role {
        //styleName: text-base/font-medium;
        font-family: Inter;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #6b7280;
      }
    }
  }
  .modal-rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-rating {
      font-size: 60px;
    }
  }

  .modal-text-area-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .modal-footer-button-area {
    display: flex;
    justify-content: space-between;
  }
}
